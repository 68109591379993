<template lang="html">
  <div class="">
    {{message}}
  </div>
</template>

<script>
import SSOService from '../../service/SSOService';

export default {
  data: () => ({
    message: '正在处理...',
    url: '',
    ticket: '',
  }),
  methods: {
    async init() {
      // 1. 存储来源地址
      const { wfcFrom } = this.$route.query;
      // 2.参数判断, 来源及客户端地址
      if (wfcFrom) {
        // 3. 获取凭证
        const { ticket } = await SSOService.getTicket();
        // 拿到凭证后跳转回去
        if (ticket) {
          // todo 组合参数
          const url = `${wfcFrom}?wfcTicket=${ticket}`;
          this.url = url;
          window.location.replace(url);
        } else {
          // todo show login form
        }
      } else {
        this.message = 'Require Params: wfcFrom';
      }
    },
  },
  mounted() {
    this.init();
  },
};
</script>
